import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Import styles
import "../styles/blog/top-post.scss";

// Bootstrap imports
import { Button, Card, Col, Container, Row } from "react-bootstrap";

const TopPost = () => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        filter: { tags: { nodes: { elemMatch: { name: { eq: "top" } } } } }
      ) {
        nodes {
          excerpt
          featuredImage {
            node {
              id
              localFile {
                url
              }
            }
          }
          id
          tags {
            nodes {
              name
            }
          }
          title
          slug
        }
      }
    }
  `);
  // Wordpress data
  const { allWpPost } = data;

  // Get backgroudn image
  let backgroundImageUrl = "";
  allWpPost.nodes.map((post) => {
    backgroundImageUrl = post.featuredImage.node.localFile.url;
  });

  // Background styles
  let backgroundStyles = {
    backgroundImage: `url(${backgroundImageUrl})`,
  };

  // Define post
  let post = allWpPost.nodes.map(({ id, title, excerpt, slug }) => (
    <section className="top-post" style={backgroundStyles}>
      <div className="top-post-overlay" />
      <Container className="top-post-content fade-in-slow">
        <div className="top-post-text">
          <h1 className="shout top-post-title">{title}</h1>
          <div
            className="talk top-post-description"
            dangerouslySetInnerHTML={{
              __html: excerpt,
            }}
          />
          <Link to={slug}>
            <Button variant="accent">Read more</Button>
          </Link>
        </div>
      </Container>
    </section>
  ));

  // Display post
  return <section className="top-post">{post}</section>;
};

export default TopPost;
