import * as React from "react";

// Import layout
import Layout from "../layouts/main";

// Import components
import PostList from "../components/blog/PostList";
import MetaData from "../components/MetaData";
import TopPost from "../components/blog/TopPost";

// Import styles
import "./styles/blog.scss";

// Import assets
import image from "../assets/images/og-image.jpg";

const BlogPage = () => {
  return (
    <Layout>
      <MetaData
        title="Blog | Flint"
        description="This is the blog page."
        image={`https://flint-fire-starter.netlify.app${image}`}
      />
      <div className="blog">
        <TopPost />
        <PostList />
      </div>
    </Layout>
  );
};

export default BlogPage;
