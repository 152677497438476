import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

// Import styles
import "../styles/blog/post-list.scss";

// Bootstrap imports
import { Button, Card, Col, Container, Row } from "react-bootstrap";

const PostList = () => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        filter: { tags: { nodes: { elemMatch: { name: { ne: "top" } } } } }
      ) {
        nodes {
          excerpt
          featuredImage {
            node {
              altText
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, formats: AUTO)
                }
              }
            }
          }
          id
          slug
          title
        }
      }
    }
  `);

  // Wordpress data
  const { allWpPost } = data;

  // Posts
  let posts = allWpPost.nodes.map(
    ({ excerpt, featuredImage, id, slug, title }) => (
      <Col className="post fade-in-slow" xs={12} sm={6} lg={4} key={id}>
        <Link className="post-link" to={slug}>
          <Card className="post-content">
            {featuredImage ? (
              <GatsbyImage
                className="post-image"
                image={
                  featuredImage.node.localFile.childImageSharp.gatsbyImageData
                }
                alt={featuredImage.node.altText}
              />
            ) : (
              <StaticImage
                className="post-image"
                src="../assets/images/logo.png"
                alt="The Adaptix logo."
                placeholder="tracedSVG"
              />
            )}
            <Card.Body>
              <div className="post-text">
                <h3 className="shout post-title">{title}</h3>
                <div
                  className="talk post-description"
                  dangerouslySetInnerHTML={{
                    __html: excerpt,
                  }}
                />
                <Button variant="accent">Read post</Button>
              </div>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    )
  );

  // If no posts, show this
  if (allWpPost.nodes.length === 0) {
    posts = (
      <Col className="post fade-in-fast" xs={12}>
        <Card>
          <Card.Body>
            <div className="text">
              <h3 className="shout">There are no posts yet!</h3>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  // Display posts
  return (
    <section className="post-list">
      <Container>
        <Row>{posts}</Row>
      </Container>
    </section>
  );
};

export default PostList;
